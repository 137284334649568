import * as React from "react";
import { Client } from "#@client/core/client";
import { Authenticator } from "#@client/authentication/authenticator";
import { string } from "yargs";
import { SignInResponse } from "#@/interfaces/authentication/signInResponse";

export interface SimpleAuthProps {
    clientCore:Client
}

export interface SimpleAuthState {
    errors?:string[];
    username:string;
    authenticated:boolean;
}       

export class SimpleAuth extends React.Component<SimpleAuthProps, SimpleAuthState>  {
    constructor(props:SimpleAuthProps) {
        super(props);
        this.state = {
            username:'',
            authenticated:true
        }
        this.auth.on("signedIn",()=>{this.setState({});})
        this.auth.on("signedOut",()=>{this.setState({});})
        
    }
    
    handleSubmit=async (event:React.FormEvent)=> {
        event.preventDefault();
        const target = event.target as typeof event.target & {
            username: {value:string},
            password: {value:string}
        }
        //console.log("Sign In",target.username)
        const result:SignInResponse = await this.auth.signIn(target.username.value,target.password.value);
        this.setState({errors:result.error?.messages});
    }

    private get auth():Authenticator {
        return this.props.clientCore.auth;
    }

    signOutClick=async (event:React.MouseEvent)=> {
        //console.log("Sign Out")
        event.preventDefault();
        await this.auth.signOut();
    }

    render() {
        if (this.auth.signedIn) {
            return <div>
            <p>Username: {this.auth.username} <button onClick={this.signOutClick}>Sign Out</button></p>
            </div>
        }
        let errors:JSX.Element|undefined=undefined;
        if (this.state.errors && this.state.errors.length>0) {
            errors = <div>{this.state.errors.join(' ')}</div>
        }
        return <div>
        <form onSubmit={this.handleSubmit}>
            <fieldset>
                <legend>Sign In</legend>
                <div>
                    <label>Username</label>
                </div>
                <div>
                    <input type="text" name="username" />
                </div>
                <br />
                <div>
                    <label>Password</label>
                </div>
                <div>
                    <input type="password" name="password" />
                </div>
                <br />
                <div>
                    <button>Sign In</button>
                </div>
                {errors}
            </fieldset>
        </form>
        </div>
        
        //<h1>Hello to you from the {this.state.username} and stuff!</h1>;
    }
}
import * as React from "react";
import * as ReactDOM from "react-dom";
import { Client } from '../core/client';
import { SimpleAuth } from './components/simpleAuth';

//import  "./astyle.css";

//import { Hello } from "./components/Hello";
//import { Slidemaster } from "./components/slidemaster";
/*<!--<Hello compiler="TypeScript" framework="React!!!" />
        <Slidemaster />-->*/
        //window.addEventListener('load', function () {
            
        //console.log('bad123',document.getElementById("example"));
ReactDOM.render(
    <div>
        Anything?
    </div>,
    document.getElementById("example")
);

/*var aUser = (window as any).authorizedUser;
ReactDOM.render(
    <SimpleAuth authorizedUser={(window as any).authorizedUser}/>,document.getElementById("simpleAuth")
);*/
//alert("It's loaded!"+(window as any).authorizedUser)
          //});
export async function react(client:Client) {
    ReactDOM.render(
        <SimpleAuth clientCore={client} />,document.getElementById("simpleAuth")
    );
}